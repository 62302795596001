<template>
    <v-container class="amp-module-page">
        <amp-distribution-result
            v-if="pressRelease.background_distribution_result"
            :result="pressRelease.background_distribution_result"
            class="mb-8"
        />

        <amp-row-first>
            <template #input>
                <status-chip
                    v-if="pressRelease.id && !isEditable"
                    class="mb-3"
                    :class="{
                        'amp-module-element-hoisted':
                            $vuetify.breakpoint.mdAndUp
                    }"
                    :large="$vuetify.breakpoint.mdAndUp"
                    :status="status"
                />

                <v-skeleton-loader
                    v-if="isLoading"
                    loading
                    type="heading"
                    class="pt-2 pb-1"
                />

                <h2 v-else class="font-weight-medium text-justify">
                    {{ pressRelease.headline || '&#160;' }}
                </h2>

                <v-divider class="my-4" />
            </template>
        </amp-row-first>

        <amp-row>
            <template #input>
                <v-skeleton-loader
                    v-if="isLoading"
                    loading
                    type="list-item-three-line"
                    class="mx-n4"
                />
                <!-- eslint-disable vue/no-v-html -->
                <h3
                    v-else
                    class="font-weight-regular text-justify"
                    v-html="pressRelease.summary || '&#160;'"
                ></h3>
                <!-- eslint-enable vue/no-v-html -->
            </template>
        </amp-row>

        <amp-row>
            <template #input>
                <v-skeleton-loader v-if="isLoading" type="image" />
                <div v-else class="py-8">
                    <v-img
                        :src="featuredMedia"
                        contain
                        max-height="60vh"
                        position="left center"
                    />
                </div>
            </template>
        </amp-row>

        <amp-row v-if="company">
            <template #input>
                <v-row class="my-0">
                    <v-col class="pt-0">
                        <v-list-item-subtitle
                            class="light-black--text font-weight-medium"
                        >
                            News provided by
                        </v-list-item-subtitle>

                        <v-list-item-title class="font-weight-medium">
                            {{ company.name }}
                        </v-list-item-title>

                        <v-list-item-subtitle
                            v-if="isLive"
                            class="secondary-color-light--text"
                        >
                            {{ publishedAt }}
                        </v-list-item-subtitle>
                    </v-col>

                    <v-col class="pt-0 text-right">
                        <v-btn
                            v-if="company.twitter_handle"
                            icon
                            :href="'https://x.com/' + company.twitter_handle"
                            target="_blank"
                        >
                            <v-icon>fab fa-square-x-twitter</v-icon>
                        </v-btn>

                        <v-btn
                            v-if="company.facebook_page"
                            icon
                            :href="
                                'https://facebook.com/' + company.facebook_page
                            "
                            target="_blank"
                        >
                            <v-icon>fab fa-facebook-square</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider class="my-4" />
            </template>
        </amp-row>

        <amp-row>
            <template #input>
                <v-skeleton-loader
                    v-if="isLoading"
                    loading
                    type="paragraph@3"
                    class="py-2"
                />
                <!-- eslint-disable vue/no-v-html -->
                <h3
                    v-else
                    class="font-weight-regular text-justify"
                    v-html="pressRelease.content || '&#160;'"
                ></h3>
                <!-- eslint-enable vue/no-v-html -->
                <v-divider class="my-4" />
            </template>
        </amp-row>

        <amp-row-last>
            <template #input>
                <v-row class="my-0">
                    <v-col md="8" class="py-0 light-black--text">
                        <v-list-item-title class="font-weight-medium">
                            Company information
                        </v-list-item-title>
                        <company-card-small
                            :company="company"
                            flat
                            class="ml-n5 company-card-light-color"
                        />
                    </v-col>
                </v-row>
            </template>
        </amp-row-last>
    </v-container>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { mapGetters } from 'vuex';

import { Endpoint } from '@/mixins';
import { InjectReactive, Watch } from '@/utils/decorators';
import { getTimezone } from '@/utils/helpers/formatDate';

import {
    AmpRow,
    AmpRowFirst,
    AmpRowLast
} from '@/components/AmpModule/AmpPage';

import { AmpDistributionResult } from '@/components/AmpModule/AmpDistributionResult';

import { CompanyCardSmall } from '@/components/CompanyCardSmall';
import { StatusChip } from '@/components/StatusChip';

import type { AmpModules } from '@/types/Announcement';
import type { PressRelease } from '@/types/PressRelease';
import type { ModuleLink } from '@/types/ModuleLink';

@Component({
    components: {
        AmpRow,
        AmpRowFirst,
        AmpRowLast,
        CompanyCardSmall,
        StatusChip,
        AmpDistributionResult
    },
    computed: {
        ...mapGetters('user', ['isClient'])
    }
})
export default class NewsArticle extends mixins(Endpoint) {
    isClient!: boolean;

    @InjectReactive({
        from: 'modules',
        default() {
            return null;
        }
    })
    modules!: AmpModules;

    @Watch('modules')
    onModulesChanged() {
        this.onMounted();
    }

    pressRelease: Partial<PressRelease> = {};

    endpoint = '/press_releases/review';

    get link(): ModuleLink[] {
        return [
            {
                type: 'primary',
                label: 'Edit',
                to: this.editLink,
                warn: this.isClient
            }
        ];
    }

    get moduleId() {
        return this.modules?.press_release_id;
    }

    get announcementId() {
        return this.$route.params.announcementId;
    }

    get sourceUrl() {
        return [this.endpoint, this.moduleId].join('/');
    }

    get editLink() {
        return `/announcements/edit/${this.announcementId}/article`;
    }

    get company() {
        return this.pressRelease.announcement?.company;
    }

    get isEditable() {
        return this.pressRelease.is_editable;
    }

    get status() {
        return this.pressRelease.status_string;
    }

    get isLive() {
        return this.pressRelease.is_live;
    }

    get publishedAt() {
        if (
            this.pressRelease &&
            this.pressRelease.pr_publish_date &&
            this.pressRelease.pr_publish_time
        ) {
            const stamp = `${this.pressRelease.pr_publish_date} ${this.pressRelease.pr_publish_time}`;

            return `${stamp} ${getTimezone(
                stamp,
                'MMM DD, YYYY h:mm a',
                this.pressRelease.timezone as string
            )}`;
        }

        return '';
    }

    get featuredMedia() {
        const resource = this.pressRelease.media_resources?.find(
            resource => resource.featured
        );

        if (resource) {
            return (
                resource.media_file.original_url ||
                resource.media_file.relative_filename ||
                resource.media_file.thumbnail_filename
            );
        }

        return '';
    }

    onMounted() {
        if (this.moduleId) {
            this.load();
        }
    }

    onData(data: { pressRelease: PressRelease }) {
        this.pressRelease = data.pressRelease;

        this.emitLinks();
    }

    emitLinks() {
        this.$emit('links', this.isEditable ? this.link : []);
    }
}
</script>

<style lang="scss">
// for higher specificity
.v-card.company-card-light-color.company-card-light-color {
    background-color: transparent !important;

    * {
        color: $light-black !important;
    }
}
</style>
